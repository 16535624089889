import axios from 'axios';

const API_URL = 'http://asdfdev.pl:8080/api';

// Tworzymy instancję axios
const axiosInstance = axios.create({
    baseURL: API_URL,
});

// Dodajemy interceptor do requestów
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("authToken"); // Pobieramy token z localStorage
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`; // Dodajemy nagłówek Authorization
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Eksportujemy funkcje z wykorzystaniem axiosInstance
export const logout = (workTimeStatistic) => axiosInstance.post(`/Auth/Logout`, workTimeStatistic);

export const fetchClients = () => axiosInstance.get(`/Client/GetAll`);
export const fetchClientById = (id) => axiosInstance.get(`/Client/Get?id=${id}`);
export const saveClient = (client) => axiosInstance.post(`/Client/Save`, client);
export const deleteClient = (id) => axiosInstance.delete(`/Client/Delete?id=${id}`);

export const fetchInsurances = () => axiosInstance.get(`/Insurance/GetAll`);
export const fetchInsuranceById = (id) => axiosInstance.get(`/Insurance/Get?id=${id}`);
export const saveInsurance = (insurance) => axiosInstance.post(`/Insurance/Save`, insurance);
export const deleteInsurance = (id) => axiosInstance.delete(`/Insurance/Delete?id=${id}`);

export const fetchUsers = () => axiosInstance.get(`/User/GetAll`);
export const fetchUserById = (id) => axiosInstance.get(`/User/Get?id=${id}`);
export const saveUser = (user) => axiosInstance.post(`/User/Save`, user);
export const changeUserPassword = (user) => axiosInstance.post(`/User/ChangePassword`, user);
export const deleteUser = (id) => axiosInstance.delete(`/User/Delete?id=${id}`);

export const fetchUserTimeStatistic = (id) => axiosInstance.get(`/Statistic/GetUserTimeStatistic?id=${id}`);
export const fetchUserClientStatistic = (id) => axiosInstance.get(`/Statistic/GetUserClientStatistic?id=${id}`);
export const fetchUserInsuranceStatistic = (id) => axiosInstance.get(`/Statistic/GetUserInsuranceStatistic?id=${id}`);
export const fetchUserTicketStatistic = (id) => axiosInstance.get(`/Statistic/GetUserTicketStatistic?id=${id}`);

