// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CategoryTabs.css */

.scrollable-tabs {
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .scrollable-tabs .nav-tabs {
    flex-wrap: nowrap;
  }
  
  .scrollable-tabs .nav-link {
    display: inline-block;
    margin-right: 0.5rem; /* Odstęp między zakładkami */
  }
  
  .scrollable-tabs .nav-link.active {
    background-color: #0d6efd; /* Kolor tła aktywnej zakładki */
    color: white; /* Kolor tekstu aktywnej zakładki */
  }
  
  .scrollable-tabs .nav-link:hover {
    background-color: #e7f1ff; /* Kolor tła zakładki na hover */
    color: #0d6efd; /* Kolor tekstu zakładki na hover */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CategoryTabs.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;IACI,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,qBAAqB;IACrB,oBAAoB,EAAE,6BAA6B;EACrD;;EAEA;IACE,yBAAyB,EAAE,gCAAgC;IAC3D,YAAY,EAAE,mCAAmC;EACnD;;EAEA;IACE,yBAAyB,EAAE,gCAAgC;IAC3D,cAAc,EAAE,mCAAmC;EACrD","sourcesContent":["/* CategoryTabs.css */\r\n\r\n.scrollable-tabs {\r\n    overflow-x: auto;\r\n    white-space: nowrap;\r\n  }\r\n  \r\n  .scrollable-tabs .nav-tabs {\r\n    flex-wrap: nowrap;\r\n  }\r\n  \r\n  .scrollable-tabs .nav-link {\r\n    display: inline-block;\r\n    margin-right: 0.5rem; /* Odstęp między zakładkami */\r\n  }\r\n  \r\n  .scrollable-tabs .nav-link.active {\r\n    background-color: #0d6efd; /* Kolor tła aktywnej zakładki */\r\n    color: white; /* Kolor tekstu aktywnej zakładki */\r\n  }\r\n  \r\n  .scrollable-tabs .nav-link:hover {\r\n    background-color: #e7f1ff; /* Kolor tła zakładki na hover */\r\n    color: #0d6efd; /* Kolor tekstu zakładki na hover */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
