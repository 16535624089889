import React, { useState, useEffect } from 'react';
import ClientForm from './ClientForm';
import { fetchClients, deleteClient } from '../api/api';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import './ClientList.css'; // Importujemy własny plik CSS dla stylów

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('add'); // 'add', 'edit', 'view'
  const [error, setError] = useState('');
  const [page, setPage] = useState(1); // Aktualna strona
  const [itemsPerPage] = useState(20); // Liczba elementów na stronę

  useEffect(() => {
    loadClients();
  }, []);

  const loadClients = async () => {
    try {
      const { data } = await fetchClients();
      setClients(data);
      setFilteredClients(data); // Inicjujemy listę filtrowaną
    } catch (err) {
      console.error('Błąd podczas ładowania klientów:', err);
      setError('Nie udało się załadować listy klientów.');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Czy na pewno chcesz usunąć tego klienta?')) {
      try {
        await deleteClient(id);
        loadClients();
      } catch (err) {
        console.error('Błąd podczas usuwania klienta:', err);
        setError('Nie udało się usunąć klienta.');
      }
    }
  };

  const handleModalOpen = (mode, id = null) => {
    setModalMode(mode);
    setSelectedClientId(id);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedClientId(null);
    loadClients();
  };

  // Obsługuje zmianę strony
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Obsługuje filtrowanie
  const handleFilterChange = (e) => {
    const query = e.target.value.toLowerCase();
    const filteredData = clients.filter((client) =>
      client.fullName.toLowerCase().includes(query) ||
      client.email.toLowerCase().includes(query) ||
      client.phoneNumber.toLowerCase().includes(query) ||
      client.pesel.toLowerCase().includes(query) ||
      client.nip.toLowerCase().includes(query)
    );
    setFilteredClients(filteredData);
    setPage(1); // Resetuj stronę na pierwszą
  };

  // Wyświetla klientów na bieżącej stronie
  const currentPageData = filteredClients.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <div>
      {error && <Alert variant="danger">{error}</Alert>}

      {/* Wyszukiwanie */}
      <input
        type="text"
        placeholder="Wyszukaj..."
        onChange={handleFilterChange}
        className="mb-3 form-control"
      />

      <Button variant="primary" onClick={() => handleModalOpen('add')} className="mb-3">
        Dodaj nowego klienta
      </Button>

      <div className="table-responsive">
        <table className="table table-hover custom-table">
          <thead>
            <tr>
              <th>Imię i nazwisko</th>
              <th>Email</th>
              <th>Telefon</th>
              <th>Akcje</th>
            </tr>
          </thead>
          <tbody>
            {currentPageData.map((client) => (
              <tr key={client.id}>
                <td>{client.fullName}</td>
                <td>{client.email}</td>
                <td>{client.phoneNumber}</td>
                <td>
                  <Button variant="info" size="sm" onClick={() => handleModalOpen('view', client.id)} className="me-2">
                    Podgląd
                  </Button>
                  <Button variant="warning" size="sm" onClick={() => handleModalOpen('edit', client.id)} className="me-2">
                    Edytuj
                  </Button>
                  <Button variant="danger" size="sm" onClick={() => handleDelete(client.id)}>
                    Usuń
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Paginacja */}
      <Pagination>
        <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page === 1} />
        {Array.from({ length: Math.ceil(filteredClients.length / itemsPerPage) }, (_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === page}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={page === Math.ceil(filteredClients.length / itemsPerPage)} />
      </Pagination>

      {/* Modal */}
      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalMode === 'add' ? 'Dodaj klienta' : modalMode === 'edit' ? 'Edytuj klienta' : 'Podgląd klienta'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Przekazanie trybu i id klienta do formularza */}
          <ClientForm 
            clientId={selectedClientId} 
            mode={modalMode} 
            onClose={handleModalClose} 
            readOnly={modalMode === 'view'}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ClientList;
