// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/UserStatistics.css */

/* Przykład zmiany koloru tła zakładki na hover */
.nav-tabs .nav-link:hover {
    background-color: #f8f9fa; /* Ustawienie własnego koloru, np. jasny szary */
    color: #000; /* Opcjonalnie, zmiana koloru tekstu */
  }
  
  /* Przykład zmiany koloru tła aktywnej zakładki */
  .nav-tabs .nav-link.active {
    background-color: #e9ecef; /* Ustawienie własnego koloru, np. niebieski */
    color: #000; /* Opcjonalnie, zmiana koloru tekstu */
  }
  
  /* Opcjonalnie, aby zapobiec zmianie koloru zakładki podczas najechania, ustaw kolor tła taki sam jak normalny */
  .nav-tabs .nav-link:hover {
    background-color: transparent; /* Brak tła */
    color: #000; /* Kolor tekstu, dostosuj według potrzeb */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/UserStatistics.css"],"names":[],"mappings":"AAAA,sCAAsC;;AAEtC,iDAAiD;AACjD;IACI,yBAAyB,EAAE,gDAAgD;IAC3E,WAAW,EAAE,sCAAsC;EACrD;;EAEA,iDAAiD;EACjD;IACE,yBAAyB,EAAE,8CAA8C;IACzE,WAAW,EAAE,sCAAsC;EACrD;;EAEA,gHAAgH;EAChH;IACE,6BAA6B,EAAE,aAAa;IAC5C,WAAW,EAAE,0CAA0C;EACzD","sourcesContent":["/* src/components/UserStatistics.css */\r\n\r\n/* Przykład zmiany koloru tła zakładki na hover */\r\n.nav-tabs .nav-link:hover {\r\n    background-color: #f8f9fa; /* Ustawienie własnego koloru, np. jasny szary */\r\n    color: #000; /* Opcjonalnie, zmiana koloru tekstu */\r\n  }\r\n  \r\n  /* Przykład zmiany koloru tła aktywnej zakładki */\r\n  .nav-tabs .nav-link.active {\r\n    background-color: #e9ecef; /* Ustawienie własnego koloru, np. niebieski */\r\n    color: #000; /* Opcjonalnie, zmiana koloru tekstu */\r\n  }\r\n  \r\n  /* Opcjonalnie, aby zapobiec zmianie koloru zakładki podczas najechania, ustaw kolor tła taki sam jak normalny */\r\n  .nav-tabs .nav-link:hover {\r\n    background-color: transparent; /* Brak tła */\r\n    color: #000; /* Kolor tekstu, dostosuj według potrzeb */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
