import React, { useState } from "react";
import axios from "axios";
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from "react-router-dom"; // Importujemy hook do nawigacji

const API_URL = "http://asdfdev.pl:8080/api";

function Login({ onLogin }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate(); // Hook do nawigacji po zalogowaniu

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post(`${API_URL}/Auth/Login`, {
            username,
            password,
          });
          const { token } = response.data;

          const decodedToken = jwtDecode(token);

          localStorage.setItem("authToken", token); // Przechowywanie tokena
          onLogin(username, decodedToken.role); // Wywołaj funkcję onLogin, aby zaktualizować stan użytkownika
          navigate("/insurances"); // Przekierowanie na stronę z polisami po zalogowaniu
        } catch (err) {
            console.log(err);
          alert("Proszę podać poprawne dane logowania.");
        }
      };


    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="card" style={{ width: "24rem" }}>
                <div className="card-body">
                    <h5 className="card-title text-center">Logowanie</h5>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">
                                Nazwa użytkownika
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                                Hasło
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary w-100">
                            Zaloguj
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
