import React, { useState, useEffect } from 'react';
import { fetchClients, saveClient, fetchClientById } from '../api/api'; // Dodaj odpowiednie API
import { Form, Button, Col, Row, Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parseISO } from 'date-fns'; // Do konwersji dat

const ClientForm = ({ clientId, onClose, readOnly }) => {
  const [client, setClient] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    bornDate: new Date(),
    //bornDate: '',
    isCompany: false,
    pesel: '',
    nip: '',
    companyName: '',
    address: '',
    details: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    // Jeśli edytujemy klienta, załaduj dane klienta
    const loadClient = async () => {
      if (clientId) {
        try {
          const response = await fetchClientById(clientId);
          setClient(response.data);
        } catch (err) {
          console.error('Błąd podczas ładowania klienta:', err);
          setError('Nie udało się załadować danych klienta.');
        }
      }
    };
console.error(readOnly);
    loadClient();
  }, [clientId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setClient({
      ...client,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const clientToSave = {
      ...client,
      bornDate: client.bornDate
        ? (client.bornDate instanceof Date
            ? client.bornDate.toISOString()
            : new Date(client.bornDate).toISOString())
        : null,
    };
    try {
      await saveClient(clientToSave);
      setSuccess('Dane klienta zostały zapisane!');
      if (onClose) onClose();
    } catch (err) {
      setError('Wystąpił błąd podczas zapisu klienta.');
      console.error(err);
    }
  };

  return (
    <div>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="firstName">
              <Form.Label>Imię</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={client.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="lastName">
              <Form.Label>Nazwisko</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={client.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="phoneNumber">
              <Form.Label>Numer telefonu</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={client.phoneNumber || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={client.email || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="bornDate">
              <Form.Label>Data urodzenia</Form.Label>
              <DatePicker
                selected={client.bornDate ? new Date(client.bornDate) : null}
                onChange={(date) => handleChange({ target: { name: 'bornDate', value: date } })}
                dateFormat="dd-MM-yyyy"
                className="form-control"
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="isCompany">
              <Form.Check
                type="checkbox"
                name="isCompany"
                label="Firma"
                checked={client.isCompany}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="pesel">
              <Form.Label>Pesel</Form.Label>
              <Form.Control
                type="text"
                name="pesel"
                value={client.pesel}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="nip">
              <Form.Label>NIP</Form.Label>
              <Form.Control
                type="text"
                name="nip"
                value={client.nip || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="companyName">
              <Form.Label>Nazwa firmy</Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                value={client.companyName || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="address">
              <Form.Label>Adres</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={client.address || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="details">
          <Form.Label>Szczegóły</Form.Label>
          <Form.Control
            as="textarea"
            name="details"
            value={client.details || ''}
            onChange={handleChange}
          />
        </Form.Group>
        {!readOnly && <Button variant="primary" type="submit">
          Zapisz
        </Button>}
        {onClose && (
          <Button variant="secondary" onClick={onClose} className="ml-2">
            Anuluj
          </Button>
        )}
      </Form>
    </div>
  );
};

export default ClientForm;
