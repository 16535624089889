import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { changeUserPassword } from '../api/api';

const UserPasswordForm = ({ show, onClose, userId }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [step, setStep] = useState(1); // 1: Confirmation, 2: Form
  const [error, setError] = useState('');

  const handleConfirm = () => {
    setStep(2); // Przejdź do formularza
  };

  const handleSubmit = () => {
    setError('');

    // Walidacja
    if (!newPassword || !confirmNewPassword) {
      setError('Wszystkie pola muszą być wypełnione.');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError('Nowe hasło i jego potwierdzenie muszą być identyczne.');
      return;
    }


    try {
      // Wywołanie API
      changeUserPassword({ id: userId, password: newPassword });
      setSuccess('Hasło zostało pomyślnie zmienione.');
      setTimeout(() => {
        onClose(); // Zamknij modal po krótkiej chwili
      }, 2000);
    } catch (err) {
      setError('Wystąpił błąd podczas zmiany hasła. Spróbuj ponownie.');
    }


    // Wywołanie funkcji przekazanej jako prop do zmiany hasła
    // onChangePassword(currentPassword, newPassword);
    onClose(); // Zamknij modal
  };

  const handleCancel = () => {
    setStep(1); // Wróć do kroku potwierdzenia
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {step === 1 ? 'Potwierdzenie zmiany hasła' : 'Zmień hasło'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step === 1 ? (
          <>
            <p>Czy na pewno chcesz zmienić hasło?</p>
            <div className="d-flex justify-content-end">
              <Button variant="secondary" onClick={onClose} className="me-2">
                Anuluj
              </Button>
              <Button variant="danger" onClick={handleConfirm}>
                Tak, zmień hasło
              </Button>
            </div>
          </>
        ) : (
          <>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Nowe hasło</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Wpisz nowe hasło"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Potwierdź nowe hasło</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Potwierdź nowe hasło"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button variant="secondary" onClick={handleCancel} className="me-2">
                  Anuluj
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  Zmień hasło
                </Button>
              </div>
            </Form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UserPasswordForm;
