import React, { useState, useEffect } from 'react';
import { fetchInsurances, deleteInsurance } from '../api/api';
import InsuranceForm from './InsuranceForm';
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import { format, differenceInDays } from 'date-fns';
import { pl } from 'date-fns/locale';
import './InsuranceList.css';  // Importujemy własny plik CSS dla stylów

const InsuranceList = () => {
  const [insurances, setInsurances] = useState([]);
  const [filteredInsurances, setFilteredInsurances] = useState([]);
  const [selectedInsuranceId, setSelectedInsuranceId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('add');
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [filters, setFilters] = useState({ isPaid: true });
  const [savedQuery, setSavedQuery] = useState('');


  useEffect(() => {
    loadInsurances();
  }, []);

  const loadInsurances = async () => {
    try {
      const response = await fetchInsurances();
      const sortedInsurances = response.data.sort((a, b) => new Date(a.dateTo) - new Date(b.dateTo));
      setInsurances(sortedInsurances);
      setFilteredInsurances(sortedInsurances);
    } catch (err) {
      console.error('Błąd podczas ładowania polis:', err);
      setError('Nie udało się załadować listy polis.');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Czy na pewno chcesz usunąć tę polisę?')) {
      try {
        await deleteInsurance(id);
        loadInsurances();
      } catch (err) {
        console.error('Błąd podczas usuwania polisy:', err);
        setError('Nie udało się usunąć polisy.');
      }
    }
  };

  const handleModalOpen = (mode, id = null) => {
    setModalMode(mode);
    setSelectedInsuranceId(id);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedInsuranceId(null);
    loadInsurances();
  };

  const getRowClass = (dateTo, isPaid) => {
    const daysLeft = differenceInDays(new Date(dateTo), new Date());
    if (daysLeft < 7) {
      if(daysLeft<0 && isPaid){
        return ''; // Domyślny brak koloru
      }else{
        return 'table-danger'; // Czerwony
      }
    } else if (daysLeft < 30) {
      return 'table-warning'; // Żółty
    }
    return ''; // Domyślny brak koloru
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleFilterChange = (e) => {
    const query = e.target.value.toLowerCase();
    const filteredData = insurances.filter((insurance) =>
      insurance.name.toLowerCase().includes(query) ||
      insurance.client.fullName.toLowerCase().includes(query) ||
      insurance.insuranceCompany.toLowerCase().includes(query)
    );
    setFilteredInsurances(filteredData);
    setPage(1);
  };

  const currentPageData = filteredInsurances.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <div>
      {error && <Alert variant="danger">{error}</Alert>}

      <input
        type="text"
        placeholder="Wyszukaj..."
        onChange={handleFilterChange}
        className="mb-3 form-control"
      />
      <Button variant="primary" onClick={() => handleModalOpen('add')} className="mb-3">
        Dodaj nową polisę
      </Button>

      <div className="table-responsive">
        <table className="table table-hover custom-table">
          <thead>
            <tr>
              <th>Nazwa</th>
              <th>Klient</th>
              <th>Ubezpieczyciel</th>
              <th>Kwota</th>
              <th>Data od</th>
              <th>Data do</th>
              <th>Akcje</th>
            </tr>
          </thead>
          <tbody>
            {currentPageData.map((insurance) => (
              <tr key={insurance.id} className={getRowClass(insurance.dateTo, insurance.isPaid)}>
                <td>{insurance.name}</td>
                <td>{insurance.client.fullName}</td>
                <td>{insurance.insuranceCompany}</td>
                <td>{insurance.amount}</td>
                <td>{format(new Date(insurance.dateFrom), 'dd-MM-yyyy', { locale: pl })}</td>
                <td>{format(new Date(insurance.dateTo), 'dd-MM-yyyy', { locale: pl })}</td>
                <td>
                  <Button variant="info" size="sm" onClick={() => handleModalOpen('view', insurance.id)} className="me-2">
                    Podgląd
                  </Button>
                  <Button variant="warning" size="sm" onClick={() => handleModalOpen('edit', insurance.id)} className="me-2">
                    Edytuj
                  </Button>
                  <Button variant="danger" size="sm" onClick={() => handleDelete(insurance.id)}>
                    Usuń
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination>
        <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page === 1} />
        {Array.from({ length: Math.ceil(filteredInsurances.length / itemsPerPage) }, (_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === page}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={page === Math.ceil(filteredInsurances.length / itemsPerPage)} />
      </Pagination>

      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalMode === 'add' && 'Dodaj nową polisę'}
            {modalMode === 'edit' && 'Edytuj polisę'}
            {modalMode === 'view' && 'Podgląd polisy'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InsuranceForm
            insuranceId={modalMode !== 'add' ? selectedInsuranceId : null}
            onClose={handleModalClose}
            readOnly={modalMode === 'view'}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InsuranceList;
