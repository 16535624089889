import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { logout } from '../api/api';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function NavBar({ user, licenceOwner, onLogout }) {
    const location = useLocation();
    const [isActive, setIsActive] = useState(true); // Czy użytkownik jest aktywny
    const [workTime, setWorkTime] = useState(0); // Łączny czas pracy (w milisekundach)
    const [breakTime, setBreakTime] = useState(0); // Łączny czas przerwy (w milisekundach)
    const [breakTimeTemp, setBreakTimeTemp] = useState(0); // Łączny czas przerwy (w milisekundach)
    const [totalTime, setTotalTime] = useState(0); // Łączny czas przerwy (w milisekundach)
    const [startTime, setStartTime] = useState(Date.now()); // Ostatni znany czas
    const [startBreakTime, setStartBreakTime] = useState(Date.now()); // Ostatni znany czas
    const [showModal, setShowModal] = useState(false); // Sterowanie widocznością modala
    const [lastActivity, setLastActivity] = useState(Date.now()); // Ostatnia aktywność
    const INACTIVITY_LIMIT = 1 * 5 * 1000; // Limit nieaktywności (10 minut w milisekundach)

  // Funkcja wysyłania raportu na backend
  const sendReport = async () => {
    try {
      await axios.post('/api/raport', {
        id: userId,
        workTime: Math.floor(workTime / 1000), // Przekazanie czasu w sekundach
        breakTime: Math.floor(breakTime / 1000),
      });
      console.log('Raport wysłany. User:', user.id, 'WorkTime:', workTime, 'BreakTime:', breakTime);
    } catch (error) {
      console.error('Błąd podczas wysyłania raportu:', error);
    }
  };
  const formatDate = (time) => {
    // Upewnij się, że `time` to obiekt typu Date
    const date = new Date(time);

    const hours = date.getHours(); // Godziny (0-23)
    const minutes = date.getMinutes(); // Minuty (0-59)
    const seconds = date.getSeconds(); // Sekundy (0-59)

    // Dodaj wiodące zera dla lepszego formatowania (np. 08:05:09)
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return formattedTime;
};


  useEffect(() => {
    const interval = setInterval(() => {
        const now = Date.now(); //Date.now();
        setTotalTime(now-startTime);

        if (now - lastActivity >= INACTIVITY_LIMIT) {
            // Użytkownik jest nieaktywny
            if (isActive) {
                setIsActive(false); // Oznacz użytkownika jako nieaktywnego
                setStartBreakTime(now);
                
            setBreakTime((prev) => prev + (now - lastActivity - INACTIVITY_LIMIT));
            }
            else{
                setBreakTime((prev) => breakTimeTemp + (now - lastActivity - INACTIVITY_LIMIT));
            }
        } else {
            setBreakTimeTemp(breakTime);
            // Użytkownik jest aktywny
            if (!isActive) {
                console.log("Powrót do stanu aktywności: " + formatDate(Date.now()));
                setIsActive(true); // Oznacz użytkownika jako aktywnego
                setLastActivity(now);
                //setBreakTime(breakTimeTemp + (now - startBreakTime));
            }
            // Dodaj czas pracy
        }

        //setWorkTime(totalTime - breakTime);
        //setLastTimestamp(now); // Aktualizuj ostatni znany czas
    }, 1000); // Interwał co 1 sekundę

    const handleActivity = () => {
        const now = Date.now();
        setLastActivity(now); // Aktualizuj czas ostatniej aktywności
        if (!isActive) {
            setIsActive(true); // Ustaw użytkownika jako aktywnego
            
            console.log("Powrót do stanu aktywności: " + formatDate(Date.now()));
            console.log("czas nieaktywnosci: " + formatDate((now - startBreakTime)));
        }
    };



    // Rejestracja zdarzeń aktywności
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);

    // Obsługa zamknięcia strony
    const handleBeforeUnload = () => {
    //   sendReport(); // Wyślij raport
    try {
        const logoutRequest = {
            BreakTime: Math.floor(breakTime/1000), 
            TotalTime: Math.floor(totalTime/1000)
        };

        logout(logoutRequest);
      } catch (err) {
          console.log(err);
        alert("Jakiś błąd.");
      }

      onLogout();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      clearInterval(interval);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [lastActivity, isActive]);



const handleLogout = () => {
    try {
        const logoutRequest = {
            BreakTime: Math.floor(breakTime/1000), 
            TotalTime: Math.floor(totalTime/1000)
        };

        logout(logoutRequest);
      } catch (err) {
          console.log(err);
        alert("Jakiś błąd.");
      }

      onLogout();
};

  const formatTime = (timeInMs) => {
    const seconds = Math.floor((timeInMs / 1000) % 60);
    const minutes = Math.floor((timeInMs / (1000 * 60)) % 60);
    const hours = Math.floor(timeInMs / (1000 * 60 * 60));
    return `${hours} h ${minutes} min ${seconds} sek`;
};

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/insurances">
                        Insurance Manager
                    </Link>
                    <div className="navbar-nav">
                        <Link
                            className={`nav-link ${location.pathname === "/insurances" ? "active" : ""}`}
                            to="/insurances"
                        >
                            Polisy ubezpieczeniowe
                        </Link>
                        <Link
                            className={`nav-link ${location.pathname === "/clients" ? "active" : ""}`}
                            to="/clients"
                        >
                            Klienci
                        </Link>
                        {licenceOwner && (
                            <Link
                                className={`nav-link ${location.pathname === "/users" ? "active" : ""}`}
                                to="/users"
                            >
                                Użytkownicy
                            </Link>
                        )}
                    </div>
                    {user && (
                        <div className="d-flex ms-auto align-items-center">
                            <span className="navbar-text me-3">
                                Witaj, <strong>{user.name}</strong>
                            </span>
                            <button
                                className="btn btn-outline-primary btn-sm me-2"
                                onClick={() => setShowModal(true)}
                            >
                                Czas pracy
                            </button>
                            <button
                                className="btn btn-outline-danger btn-sm"
                                //onClick={onLogout((totalTime-breakTime, breakTime, totalTime))}
                                onClick={handleLogout}
                            >
                                Wyloguj
                            </button>
                        </div>
                    )}
                </div>
            </nav>

            {/* Modal z czasem pracy */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Czas pracy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Czas pracy:</strong>{" "}
                        {formatTime(totalTime - breakTime)}
                    </p>
                    <p>
                        <strong>Czas przerwy:</strong>{" "}
                        {formatTime(breakTime)}
                    </p>
                    <p>
                        <strong>Łącznie:</strong>{" "}
                        {formatTime(totalTime)}
                    </p>
                    <p><strong>Status:</strong> {isActive ? "Pracuje" : "Na przerwie"}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Zamknij
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default NavBar;
