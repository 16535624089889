// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom styles for the Insurance List Table */
.custom-table {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-table th,
.custom-table td {
  padding: 15px;
  vertical-align: middle;
  text-align: center;
}

.custom-table th {
  background-color: #f8f9fa;
  color: #495057;
  font-weight: bold;
}

.custom-table tr {
  transition: background-color 0.3s;
}

.custom-table tr:hover {
  background-color: #f1f1f1;
}

.custom-table .table-danger {
  background-color: #f8d7da !important;
}

.custom-table .table-warning {
  background-color: #fff3cd !important;
}

.custom-table .btn {
  margin: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/InsuranceList.css"],"names":[],"mappings":"AAAA,+CAA+C;AAC/C;EACE,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;;EAEE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,WAAW;AACb","sourcesContent":["/* Custom styles for the Insurance List Table */\r\n.custom-table {\r\n  border-radius: 8px;\r\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.custom-table th,\r\n.custom-table td {\r\n  padding: 15px;\r\n  vertical-align: middle;\r\n  text-align: center;\r\n}\r\n\r\n.custom-table th {\r\n  background-color: #f8f9fa;\r\n  color: #495057;\r\n  font-weight: bold;\r\n}\r\n\r\n.custom-table tr {\r\n  transition: background-color 0.3s;\r\n}\r\n\r\n.custom-table tr:hover {\r\n  background-color: #f1f1f1;\r\n}\r\n\r\n.custom-table .table-danger {\r\n  background-color: #f8d7da !important;\r\n}\r\n\r\n.custom-table .table-warning {\r\n  background-color: #fff3cd !important;\r\n}\r\n\r\n.custom-table .btn {\r\n  margin: 2px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
