import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";
import { logout } from './api/api';
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/NavBar";
import Login from "./components/Login";
import InsuranceList from "./components/InsuranceList";
import ClientList from "./components/ClientList";
import UserList from "./components/UserList";

const API_URL = "http://asdfdev.pl:8080/api";


function App() {
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);

    const handleLogin = (username, role) => {
        setUser(username); // Logowanie użytkownika
        setRole(role); // Logowanie użytkownika
    };

    const handleLogout = () => {


        setUser(null); // Wylogowanie użytkownika
    };

    return (
        <Router>
            <div>
                {user && (
                    <NavBar user={user} licenceOwner={role === "LicenceOwner"} onLogout={handleLogout} />
                )}
                <div className="container mt-4">
                    <Routes>
                        <Route
                            path="/login"
                            element={<Login onLogin={handleLogin} />}
                        />
                        <Route
                            path="/insurances"
                            element={
                                user ? (
                                    <InsuranceList />
                                ) : (
                                    <Navigate to="/login" />
                                )
                            }
                        />
                        <Route
                            path="/clients"
                            element={
                                user ? (
                                    <ClientList />
                                ) : (
                                    <Navigate to="/login" />
                                )
                            }
                        />
                        <Route
                            path="/users"
                            element={
                                user ? (
                                    <UserList />
                                ) : (
                                    <Navigate to="/login" />
                                )
                            }
                        />
                        <Route
                            path="/"
                            element={
                                user ? (
                                    <Navigate to="/insurances" />
                                ) : (
                                    <Navigate to="/login" />
                                )
                            }
                        />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
