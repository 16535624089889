// src/components/UserStatistics.js

import React, { useState, useEffect } from 'react';
import { 
  fetchUserTimeStatistic, 
  fetchUserClientStatistic, 
  fetchUserInsuranceStatistic, 
  fetchUserTicketStatistic 
} from '../api/api';
import { Spinner, Alert, Tabs, Tab, Button } from 'react-bootstrap';
import CategoryTabs from './CategoryTabs';
import 'chart.js/auto';
import './UserStatistics.css';

const UserStatistics = ({ userId }) => {
  const [statistics, setStatistics] = useState({
    workTimeStatistic: [],
    workClientStatistic: [],
    workInsuranceStatistic: [],
    workTicketStatistic: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Funkcja do formatowania sekund na 'x h y min z sec'
  const formatSeconds = (totalSeconds) => {
    if (isNaN(totalSeconds) || totalSeconds < 0) {
      return '0 sec';
    }

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let result = '';
    if (hours > 0) {
      result += `${hours} h `;
    }
    if (minutes > 0) {
      result += `${minutes} min `;
    }
    result += `${seconds} sec`;

    return result.trim();
  };

  useEffect(() => {
    if (!userId) {
        setLoading(false);
        setStatistics({
          workTimeStatistic: [],
          workClientStatistic: [],
          workInsuranceStatistic: [],
          workTicketStatistic: [],
        });
        return;
      }
    const fetchStatistics = async () => {
      try {
        const [
          workTimeRes,
          workClientTimeRes,
          workInsuranceTimeRes,
          workTicketTimeRes,
        ] = await Promise.all([
          fetchUserTimeStatistic(userId),
          fetchUserClientStatistic(userId),
          fetchUserInsuranceStatistic(userId),
          fetchUserTicketStatistic(userId),
        ]);

        // console.log('Fetched Statistics:', {
        //   workTimeStatistic: workTimeRes.data,
        //   workClientStatistic: workClientTimeRes.data,
        //   workInsuranceStatistic: workInsuranceTimeRes.data,
        //   workTicketStatistic: workTicketTimeRes.data,
        // });

        setStatistics({
          workTimeStatistic: Array.isArray(workTimeRes.data) ? workTimeRes.data : [],
          workClientStatistic: Array.isArray(workClientTimeRes.data) ? workClientTimeRes.data : [],
          workInsuranceStatistic: Array.isArray(workInsuranceTimeRes.data) ? workInsuranceTimeRes.data : [],
          workTicketStatistic: Array.isArray(workTicketTimeRes.data) ? workTicketTimeRes.data : [],
        });
      } catch (err) {
        setError('Nie udało się pobrać statystyk użytkownika.');
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics();
  }, [userId]);

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status" />
        <span className="ms-2">Ładowanie statystyk...</span>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  const operationTypes = {
    1: 'Dodanie',
    2: 'Edycja',
    3: 'Usunięcie',
    4: 'Podgląd',
    5: 'Zamknięcie',
    6: 'Sms',
    7: 'Email',
    8: 'Inne'
  };

  return (
    <div className="user-statistics">
      <Tabs defaultActiveKey="workTime" id="main-tabs" className="mb-3">
        {/* Godziny pracy */}
        <Tab eventKey="workTime" title="Godziny pracy">
          <CategoryTabs 
            categoryData={statistics.workTimeStatistic}
            categoryName="Godziny pracy"
            dateAccessor="loginTime"
            columns={[
            //   { header: 'ID', accessor: 'id' },
              { header: 'Czas logowania', accessor: 'loginTime' },
              { header: 'Czas wylogowania', accessor: 'logoutTime' },
              { header: 'Czas pracy', accessor: 'workTime' },
              { header: 'Czas przerwy', accessor: 'breakTime' },
              { header: 'Czas łączny', accessor: 'totalTime' },
              { header: 'Wykres', accessor: 'chart' },
            ]}
            renderRow={(item, accessor, handleShowModal) => {
              if (accessor === 'loginTime' || accessor === 'logoutTime') {
                return new Date(item[accessor]).toLocaleTimeString();
              }
              if (accessor === 'workTime' || accessor === 'breakTime' || accessor === 'totalTime') {
                return formatSeconds(item[accessor]);
              }
              if (accessor === 'chart') {
                return (
                  <Button 
                    variant="primary" 
                    size="sm" 
                    onClick={() => handleShowModal(item)}
                  >
                    Wykres
                  </Button>
                );
              }
              return item[accessor];
            }}
            showSummary={true}
            operationTypes={operationTypes}
            formatSeconds={formatSeconds}
          />
        </Tab>

        {/* Klienci */}
        <Tab eventKey="clients" title="Klienci">
          <CategoryTabs 
            categoryData={statistics.workClientStatistic}
            categoryName="Klienci"
            dateAccessor="operationTime"
            columns={[
            //   { header: 'ID', accessor: 'id' },
              { header: 'Czas operacji', accessor: 'operationTime' },
              { header: 'Typ operacji', accessor: 'operationType' },
              { header: 'ID encji', accessor: 'entityId' },
            ]}
            renderRow={(item, accessor, handleShowModal) => {
              if (accessor === 'operationTime') {
                return new Date(item[accessor]).toLocaleTimeString();
              }
              if (accessor === 'operationType') {
                return operationTypes[item[accessor]] || 'Nieznany typ';
              }
              return item[accessor];
            }}
            showSummary={false}
            operationTypes={operationTypes}
            formatSeconds={formatSeconds}
          />
        </Tab>

        {/* Polisy */}
        <Tab eventKey="policies" title="Polisy">
          <CategoryTabs 
            categoryData={statistics.workInsuranceStatistic}
            categoryName="Polisy"
            dateAccessor="operationTime"
            columns={[
            //   { header: 'ID', accessor: 'id' },
              { header: 'Czas operacji', accessor: 'operationTime' },
              { header: 'Typ operacji', accessor: 'operationType' },
              { header: 'ID encji', accessor: 'entityId' },
            ]}
            renderRow={(item, accessor, handleShowModal) => {
              if (accessor === 'operationTime') {
                return new Date(item[accessor]).toLocaleTimeString();
              }
              if (accessor === 'operationType') {
                return operationTypes[item[accessor]] || 'Nieznany typ';
              }
              return item[accessor];
            }}
            showSummary={false}
            operationTypes={operationTypes}
            formatSeconds={formatSeconds}
          />
        </Tab>

        {/* Zgłoszenia */}
        <Tab eventKey="tickets" title="Zgłoszenia">
          <CategoryTabs 
            categoryData={statistics.workTicketStatistic}
            categoryName="Zgłoszenia"
            dateAccessor="operationTime"
            columns={[
            //   { header: 'ID', accessor: 'id' },
              { header: 'Czas operacji', accessor: 'operationTime' },
              { header: 'Typ operacji', accessor: 'operationType' },
              { header: 'ID encji', accessor: 'entityId' },
            ]}
            renderRow={(item, accessor, handleShowModal) => {
              if (accessor === 'operationTime') {
                return new Date(item[accessor]).toLocaleTimeString();
              }
              if (accessor === 'operationType') {
                return operationTypes[item[accessor]] || 'Nieznany typ';
              }
              return item[accessor];
            }}
            showSummary={false}
            operationTypes={operationTypes}
            formatSeconds={formatSeconds}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

// Funkcje handleShowModal i handleCloseModal przeniesione do CategoryTabs.js

export default UserStatistics;
