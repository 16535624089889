// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom styles for the Client List Table */
.custom-table {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-table th,
.custom-table td {
  padding: 15px;
  vertical-align: middle;
  text-align: center;
}

.custom-table th {
  background-color: #f8f9fa;
  color: #495057;
  font-weight: bold;
}

.custom-table tr {
  transition: background-color 0.3s;
}

.custom-table tr:hover {
  background-color: #f1f1f1;
}

.custom-table .btn {
  margin: 2px;
}

.table-responsive {
  margin-bottom: 30px;
}

`, "",{"version":3,"sources":["webpack://./src/components/ClientList.css"],"names":[],"mappings":"AAAA,4CAA4C;AAC5C;EACE,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;;EAEE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["/* Custom styles for the Client List Table */\n.custom-table {\n  border-radius: 8px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n.custom-table th,\n.custom-table td {\n  padding: 15px;\n  vertical-align: middle;\n  text-align: center;\n}\n\n.custom-table th {\n  background-color: #f8f9fa;\n  color: #495057;\n  font-weight: bold;\n}\n\n.custom-table tr {\n  transition: background-color 0.3s;\n}\n\n.custom-table tr:hover {\n  background-color: #f1f1f1;\n}\n\n.custom-table .btn {\n  margin: 2px;\n}\n\n.table-responsive {\n  margin-bottom: 30px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
