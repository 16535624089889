import React, { useState, useEffect } from 'react';
import { fetchClients, saveInsurance, fetchInsuranceById } from '../api/api';
import { Form, Button, Col, Row, Alert, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parseISO } from 'date-fns'; // Do konwersji dat

const InsuranceForm = ({ insuranceId, onClose, readOnly }) => {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [insurance, setInsurance] = useState({
    name: '',
    insuranceCompany: '',
    amount: 0,
    type: '',
    dateFrom: new Date(),
    dateTo: new Date(),
    clientId: '',
    isPaid: false,
    details: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // Nowe stany do obsługi modalu
  const [showClientModal, setShowClientModal] = useState(false); // Stan modalu
  const [selectedClient, setSelectedClient] = useState(null); // Wybrany klient

  useEffect(() => {
    // Pobieranie listy klientów
    const loadClients = async () => {
    if (clients.length === 0) {  // Sprawdzamy, czy klienci już zostały załadowani
      try {
        const response = await fetchClients();
        setClients(response.data);
        setFilteredClients(response.data);  // Inicjalizuj filtrowaną listę klientów
      } catch (err) {
        console.error('Błąd podczas pobierania klientów:', err);
        setError('Nie udało się załadować listy klientów.');
      }
    }
  };

    // Pobieranie danych polisy, jeśli edytujemy
    const loadInsurance = async () => {
      if (insuranceId) {
        try {
          const response = await fetchInsuranceById(insuranceId);
          setInsurance(response.data);
		  setInsurance({
          ...response.data,
          dateFrom: response.data.dateFrom ? new Date(response.data.dateFrom) : null,
          dateTo: response.data.dateTo ? new Date(response.data.dateTo) : null,
        });
		
		//if (clients.length > 0) {
          const client = clients.find(client => client.id === response.data.clientId);
          setSelectedClient(client);
        //}
		
        } catch (err) {
          console.error('Błąd podczas ładowania polisy:', err);
          setError('Nie udało się załadować szczegółów polisy.');
        }
      }
    };

    loadClients().then(() => loadInsurance());
  }, [insuranceId, selectedClient]);


  // Funkcja do filtrowania klientów
  const handleSearchChange = (e) => {
    const { value } = e.target;
    const filtered = clients.filter(client =>
      client.fullName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredClients(filtered);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setInsurance({
      ...insurance,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

	const handleSubmit = async (e) => {
	  e.preventDefault();

	  // Upewnij się, że dateFrom i dateTo są obiektami Date
	  const insuranceToSave = {
		...insurance,
		dateFrom: insurance.dateFrom instanceof Date ? insurance.dateFrom.toISOString() : null,
		dateTo: insurance.dateTo instanceof Date ? insurance.dateTo.toISOString() : null,
		client: null
	  };

	  try {
		await saveInsurance(insuranceToSave);
		setSuccess('Polisa została zapisana!');
		if (onClose) onClose();
	  } catch (err) {
		setError('Wystąpił błąd podczas zapisu polisy.');
		console.error(err);
	  }
	};

  // Funkcja do otwierania modalu z listą klientów
  const handleClientSelect = (client) => {
    setSelectedClient(client);
    setInsurance({ ...insurance, clientId: client.id });
    setShowClientModal(false);
  };

  return (
    <div>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="insuranceName">
              <Form.Label>Nazwa</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={insurance.name}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="type">
              <Form.Label>Rodzaj polisy</Form.Label>
              <Form.Control
                type="text"
                name="type"
                value={insurance.type}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="insuranceCompany">
              <Form.Label>Ubezpieczyciel</Form.Label>
              <Form.Control
                type="text"
                name="insuranceCompany"
                value={insurance.insuranceCompany}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="amount">
              <Form.Label>Kwota</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={insurance.amount}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="dateFrom">
              <Form.Label>Data od</Form.Label>
              <DatePicker
                selected={insurance.dateFrom ? new Date(insurance.dateFrom) : null}
                onChange={(date) => handleChange({ target: { name: 'dateFrom', value: date } })}
                dateFormat="dd-MM-yyyy"
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="dateTo">
              <Form.Label>Data do</Form.Label>
              <DatePicker
                selected={insurance.dateTo ? new Date(insurance.dateTo) : null}
                onChange={(date) => handleChange({ target: { name: 'dateTo', value: date } })}
                dateFormat="dd-MM-yyyy"
                className="form-control"
              />
            </Form.Group>
          </Col>
        </Row>
        
        {/* Kombinacja z otwieraniem modalu dla klienta */}
        <Form.Group controlId="clientId">
          <Form.Label>Klient</Form.Label>
          <div>
            {!readOnly && <Button
              variant="secondary"
              onClick={() => setShowClientModal(true)}
            >
              Wybierz klienta
            </Button>}
            <span>{selectedClient ? selectedClient.fullName : "Nie wybrano klienta"}</span>
          </div>
        </Form.Group>
        
        <Form.Group controlId="isPaid">
          <Form.Check
            type="checkbox"
            name="isPaid"
            label="Opłacone"
            checked={insurance.isPaid}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="details">
          <Form.Label>Szczegóły</Form.Label>
          <Form.Control
            as="textarea"
            name="details"
            value={insurance.details}
            onChange={handleChange}
          />
        </Form.Group>
        {!readOnly && <Button variant="primary" type="submit">
          Zapisz
        </Button>}
        {onClose && (
          <Button variant="secondary" onClick={onClose} className="ml-2">
            Anuluj
          </Button>
        )}
      </Form>

      {/* Modal z listą klientów */}
      <Modal show={showClientModal} onHide={() => setShowClientModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Wybierz klienta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Wyszukaj klienta"
            onChange={handleSearchChange}
          />
          <ul className="list-group mt-2">
            {filteredClients.map((client) => (
              <li
                key={client.id}
                className="list-group-item"
                onClick={() => handleClientSelect(client)}
                style={{ cursor: 'pointer' }}
              >
                {client.fullName}
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowClientModal(false)}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InsuranceForm;
