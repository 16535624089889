// src/components/CategoryTabs.js

import React, { useState, useMemo } from 'react';
import { Alert, Button, Modal, Row, Col, Pagination } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO, format, isValid } from 'date-fns';
import './CategoryTabs.css';

// Custom Input Component for DatePicker
const DateButton = React.forwardRef(({ value, onClick }, ref) => (
  <Button variant="primary" onClick={onClick} ref={ref}>
    {value}
  </Button>
));

const CategoryTabs = ({ 
  categoryData, 
  categoryName, 
  columns, 
  renderRow, 
  dateAccessor, 
  showSummary = false, 
  operationTypes,
  formatSeconds
}) => {
  if (!Array.isArray(categoryData) || categoryData.length === 0) {
    return <Alert variant="info">Brak danych dla {categoryName}.</Alert>;
  }

  // Funkcja do grupowania danych według daty
  const groupByDate = (data) => {
    return data.reduce((acc, item) => {
      const dateString = item[dateAccessor];
      if (!dateString) return acc; // Jeśli brak daty, pomiń

      const parsedDate = parseISO(dateString);
      if (!isValid(parsedDate)) return acc; // Sprawdź, czy data jest prawidłowa

      const formattedDate = format(parsedDate, 'yyyy-MM-dd'); // Używamy formatu ISO

      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push(item);
      return acc;
    }, {});
  };

  const groupedData = useMemo(() => groupByDate(categoryData), [categoryData, dateAccessor]);
  console.log(`Grouped Data for ${categoryName}:`, groupedData);

  const availableDates = Object.keys(groupedData).sort((a, b) => new Date(b) - new Date(a));
  console.log(`Available Dates for ${categoryName}:`, availableDates);

  if (!Array.isArray(availableDates) || availableDates.length === 0) {
    return <Alert variant="info">Brak danych dla {categoryName}.</Alert>;
  }

  // Przekształcenie dostępnych dat na obiekty Date
  const availableDateObjects = useMemo(() => 
    availableDates.map(dateStr => parseISO(dateStr)).filter(date => isValid(date)),
    [availableDates]
  );

  if (availableDateObjects.length === 0) {
    return <Alert variant="info">Brak prawidłowych dat dla {categoryName}.</Alert>;
  }

  // Stan dla wybranej daty (domyślnie najnowsza data)
  const [selectedDate, setSelectedDate] = useState(availableDateObjects[0]);

  // Funkcja do formatowania daty w celu dopasowania do grupowanych danych
  const formatSelectedDate = (date) => {
    return format(date, 'yyyy-MM-dd');
  };

  const formattedSelectedDate = formatSelectedDate(selectedDate);
  const selectedItems = groupedData[formattedSelectedDate] || [];

  // Reszta stanu komponentu pozostaje bez zmian
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [summaryModalData, setSummaryModalData] = useState(null);

  // Stan dla paginacji
  const [currentPage, setCurrentPage] = useState(1);

  const ITEMS_PER_PAGE = 10;

  // Funkcje do otwierania i zamykania modali pozostają bez zmian
  const handleShowModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalData(null);
  };

  const handleShowSummaryModal = (summaryData, date) => {
    setSummaryModalData({ ...summaryData, date });
    setShowSummaryModal(true);
  };

  const handleCloseSummaryModal = () => {
    setShowSummaryModal(false);
    setSummaryModalData(null);
  };

  // Funkcja do obsługi zmiany daty
  const handleDateChange = (date) => {
    if (isValid(date)) {
      setSelectedDate(date);
      setCurrentPage(1); // Resetuj paginację po zmianie daty
    }
  };

  const REGULAMINOWY_CZAS_PRACY = 8 * 3600; // 8 godzin w sekundach

  // Funkcje do generowania danych dla wykresów pozostają bez zmian
  const getChartData = () => {
    if (!modalData) return {};

    const { workTime, breakTime } = modalData;

    const safeWorkTime = typeof workTime === 'number' ? workTime : 0;
    const safeBreakTime = typeof breakTime === 'number' ? breakTime : 0;

    return {
      labels: ['Czas pracy', 'Czas przerwy'],
      datasets: [
        {
          data: [safeWorkTime, safeBreakTime],
          backgroundColor: [
            '#36A2EB', // Niebieski
            '#FF6384'  // Czerwony
          ],
          hoverBackgroundColor: [
            '#36A2EB',
            '#FF6384'
          ]
        }
      ]
    };
  };

  const getSummaryChartData = () => {
    if (!summaryModalData) return {};

    const { totalWorkTime, totalBreakTime } = summaryModalData;

    const safeTotalWorkTime = typeof totalWorkTime === 'number' ? totalWorkTime : 0;
    const safeTotalBreakTime = typeof totalBreakTime === 'number' ? totalBreakTime : 0;

    return {
      labels: ['Czas pracy', 'Czas przerwy'],
      datasets: [
        {
          data: [safeTotalWorkTime, safeTotalBreakTime],
          backgroundColor: [
            '#36A2EB', // Niebieski
            '#FF6384'  // Czerwony
          ],
          hoverBackgroundColor: [
            '#36A2EB',
            '#FF6384'
          ]
        }
      ]
    };
  };

  const getRegulaminChartData = () => {
    if (!summaryModalData) return {};

    const { totalTotalTime } = summaryModalData;

    const safeTotalTotalTime = typeof totalTotalTime === 'number' ? totalTotalTime : 0;

    return {
      labels: ['Łączny czas pracy', 'Regulaminowy czas pracy'],
      datasets: [
        {
          data: [safeTotalTotalTime, REGULAMINOWY_CZAS_PRACY],
          backgroundColor: [
            '#4BC0C0', // Zielony
            '#FF9F40'  // Pomarańczowy
          ],
          hoverBackgroundColor: [
            '#4BC0C0',
            '#FF9F40'
          ]
        }
      ]
    };
  };

  const getChartOptions = (title) => ({
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.label || '';
            const value = context.parsed;
            return `${label}: ${formatSeconds(value)}`;
          }
        }
      },
      title: {
        display: true,
        text: title,
      },
    },
  });

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = (totalItems) => {
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
    if (totalPages <= 1) return null;

    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item 
          key={number} 
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>,
      );
    }

    return (
      <Pagination>{items}</Pagination>
    );
  };

  // Obsługa wyświetlania podsumowania
  let summary = null;
  if (showSummary && categoryName === "Godziny pracy" && selectedItems.length > 0) {
    const earliestLogin = selectedItems.reduce((earliest, item) => {
      const current = parseISO(item.loginTime);
      return isValid(current) && current < earliest ? current : earliest;
    }, parseISO(selectedItems[0].loginTime));

    const latestLogout = selectedItems.reduce((latest, item) => {
      const current = parseISO(item.logoutTime);
      return isValid(current) && current > latest ? current : latest;
    }, parseISO(selectedItems[0].logoutTime));

    const totalWorkTime = selectedItems.reduce((sum, item) => sum + (typeof item.workTime === 'number' ? item.workTime : 0), 0);
    const totalBreakTime = selectedItems.reduce((sum, item) => sum + (typeof item.breakTime === 'number' ? item.breakTime : 0), 0);
    const totalTotalTime = selectedItems.reduce((sum, item) => sum + (typeof item.totalTime === 'number' ? item.totalTime : 0), 0);

    summary = {
      earliestLogin: isValid(earliestLogin) ? format(earliestLogin, 'HH:mm:ss') : 'N/A',
      latestLogout: isValid(latestLogout) ? format(latestLogout, 'HH:mm:ss') : 'N/A',
      totalWorkTime,
      totalBreakTime,
      totalTotalTime,
    };
  }

  // Paginacja
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedItems = selectedItems.slice(startIndex, endIndex);

  // Obsługa braku danych dla wybranej daty
  if (selectedItems.length === 0) {
    return (
      <>
        {/* Sekcja Wyboru Daty */}
        <div className="date-selector-container mb-4">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            includeDates={availableDateObjects}
            customInput={<DateButton />}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <Alert variant="info">Brak danych dla wybranej daty.</Alert>
      </>
    );
  }

  return (
    <>
      {/* Sekcja Wyboru Daty */}
      <div className="date-selector-container mb-4">
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          includeDates={availableDateObjects}
          customInput={<DateButton />}
          dateFormat="dd/MM/yyyy"
        />
      </div>

      {/* Sekcja Wybranej Daty */}
      <div className="selected-date-content">
        <div className="mt-3">
          <h6>{categoryName} - {format(selectedDate, 'dd/MM/yyyy')}</h6>

          {summary && (
            <div className="summary-section d-flex justify-content-between align-items-center mb-3 p-3 bg-light border rounded">
              <div>
                <strong>Podsumowanie:</strong><br/>
                Najwcześniejsze logowanie: {summary.earliestLogin}<br/>
                Najpóźniejsze wylogowanie: {summary.latestLogout}<br/>
                Suma czasu pracy: {formatSeconds(summary.totalWorkTime)}<br/>
                Suma czasu przerwy: {formatSeconds(summary.totalBreakTime)}<br/>
                Suma czasu łącznego: {formatSeconds(summary.totalTotalTime)}
              </div>
              <Button 
                variant="success" 
                size="sm" 
                onClick={() => handleShowSummaryModal(summary, format(selectedDate, 'dd/MM/yyyy'))}
              >
                Wykres
              </Button>
            </div>
          )}

          <table className="table table-bordered">
            <thead>
              <tr>
                {Array.isArray(columns) && columns.map((col) => (
                  <th key={col.header}>{col.header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(paginatedItems) && paginatedItems.map((item) => (
                <tr key={item.id}>
                  {Array.isArray(columns) && columns.map((col) => (
                    <td key={col.header}>{renderRow(item, col.accessor, handleShowModal)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          {/* Renderowanie kontrolki paginacji */}
          {renderPagination(selectedItems.length)}
        </div>
      </div>

      {/* Modal dla wykresu podsumowania */}
      <Modal show={showSummaryModal} onHide={handleCloseSummaryModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Wykres Podsumowania Czasu Pracy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {summaryModalData && (
            <Row>
              <Col sm={12} md={6} className="mb-4">
                <Pie
                  data={getSummaryChartData()}
                  options={getChartOptions(`Czas pracy i przerwy dnia ${summaryModalData.date}`)}
                />
              </Col>
              <Col sm={12} md={6}>
                <Pie
                  data={getRegulaminChartData()}
                  options={getChartOptions(`Łączny czas pracy vs Regulaminowy czas pracy dnia ${summaryModalData.date}`)}
                />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSummaryModal}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal dla wykresu pojedynczego wiersza */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Wykres Czasu Pracy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData && (
            <Pie
              data={getChartData()}
              options={getChartOptions(`Czas pracy i przerwy dnia ${format(parseISO(modalData.loginTime), 'dd/MM/yyyy')}`)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CategoryTabs;
