import React, { useState, useEffect } from 'react';
import { fetchUserById, saveUser } from '../api/api';
import { Form, Button, Col, Row, Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import UserPasswordForm from './UserPasswordForm'; // Import komponentu modalu

const UserForm = ({ userId, onClose, readOnly, addNew }) => {
  const [user, setUser] = useState({
    login: '',
    password: '',
    firstName: '',
    lastName: '',
    bornDate: null,
    isActive: false,
    isLicenceOwner: false,
    phoneNumber: '',
    email: '',
    details: '',
    licenceOwner: {
      id: '', // Powinno być liczbą lub pustym stringiem
      companyName: '',
    },
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  // Nowe stany do obsługi modalu
  const [showUserPasswordModal, setShowUserPasswordModal] = useState(false); // Stan do obsługi modalu

  useEffect(() => {
    if (userId) {
      const loadUser = async () => {
        try {
          const response = await fetchUserById(userId);
          setUser({
            ...response.data,
            licenceOwner: response.data.licenceOwner || { id: '', companyName: '' },
          });
        } catch (err) {
          console.error('Błąd podczas ładowania użytkownika:', err);
          setError('Nie udało się załadować danych użytkownika.');
        }
      };
      loadUser();
    }
  }, [userId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUser({
      ...user,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleNestedChange = (key, subKey, value) => {
    setUser({
      ...user,
      [key]: {
        ...user[key],
        [subKey]: value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedUser = {
        ...user,
        licenceOwner: user.licenceOwner.id
          ? { ...user.licenceOwner, id: Number(user.licenceOwner.id) }
          : null,
      };
      await saveUser(formattedUser);
      setSuccess('Dane użytkownika zostały zapisane!');
      if (onClose) onClose();
    } catch (err) {
      setError('Wystąpił błąd podczas zapisu użytkownika.');
      console.error(err);
    }
  };

  return (
    <div>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="login">
              <Form.Label>Login</Form.Label>
              <Form.Control
                type="text"
                name="login"
                value={user.login}
                onChange={handleChange}
                required
                readOnly={readOnly}
              />
            </Form.Group>
          </Col>
          {
             <Col>
            {(!readOnly && addNew) && (
              <Form.Group controlId="password">
                <Form.Label>Hasło</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={user.password}
                  onChange={handleChange}
                  required={!userId} // Wymagane tylko przy tworzeniu
                />
              </Form.Group>
            )}
          </Col> 
          }
          {/* Przyciski do otwierania modalu zmiany hasła */}
          {(!readOnly && !addNew) && (
              <div className="mt-4">
                <Button variant="primary" onClick={() => setShowUserPasswordModal(true)}>
                  Zmień hasło
                </Button>
              </div>
            )}
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="firstName">
              <Form.Label>Imię</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={user.firstName}
                onChange={handleChange}
                required
                readOnly={readOnly}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="lastName">
              <Form.Label>Nazwisko</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={user.lastName}
                onChange={handleChange}
                required
                readOnly={readOnly}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="bornDate">
              <Form.Label>Data urodzenia</Form.Label>
              <DatePicker
                selected={user.bornDate ? new Date(user.bornDate) : null}
                onChange={(date) =>
                  handleChange({ target: { name: 'bornDate', value: date } })
                }
                dateFormat="dd-MM-yyyy"
                className="form-control"
                required
                readOnly={readOnly}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="isActive">
              <Form.Check
                type="checkbox"
                name="isActive"
                label="Aktywny"
                checked={user.isActive}
                onChange={handleChange}
                disabled={readOnly}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {/* <Col>
            <Form.Group controlId="isLicenceOwner">
              <Form.Check
                type="checkbox"
                name="isLicenceOwner"
                label="Właściciel licencji"
                checked={user.isLicenceOwner}
                onChange={handleChange}
                disabled={readOnly}
              />
            </Form.Group>
          </Col> */}
          {readOnly && (<Col>
            <Form.Group controlId="licenceOwnerId">
              <Form.Label>Właściciel licencji</Form.Label>
              <Form.Control
                type="text"
                name="licenceOwnerId"
                value={user.licenceOwner.companyName}
                onChange={handleChange}
                required
                readOnly={readOnly}
              />
            </Form.Group>
          </Col>)}
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="phoneNumber">
              <Form.Label>Numer telefonu</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={user.phoneNumber || ''}
                onChange={handleChange}
                readOnly={readOnly}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={user.email || ''}
                onChange={handleChange}
                readOnly={readOnly}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="details">
          <Form.Label>Szczegóły</Form.Label>
          <Form.Control
            as="textarea"
            name="details"
            value={user.details || ''}
            onChange={handleChange}
            readOnly={readOnly}
          />
        </Form.Group>
        {!readOnly && (
          <Button variant="primary" type="submit">
            Zapisz
          </Button>
        )}
        {onClose && (
          <Button variant="secondary" onClick={onClose} className="ml-2">
            Anuluj
          </Button>
        )}
      </Form>
      {/* Modal do zmiany hasła */}
      <UserPasswordForm
        show={showUserPasswordModal}
        onClose={() => setShowUserPasswordModal(false)}
        userId={user.id}
        // onChangePassword={(currentPassword, newPassword) => {
        //   console.log('Obecne hasło:', currentPassword);
        //   console.log('Nowe hasło:', newPassword);
        //   setShowUserPasswordModal(false);
        // }}
      />
    </div>
  );
};

export default UserForm;
