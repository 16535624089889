import React, { useState, useEffect } from 'react';
import UserForm from './UserForm';
import { fetchUsers, deleteUser } from '../api/api'; // Funkcje do komunikacji z API
import { Button, Modal, Alert, Pagination } from 'react-bootstrap';
import UserStatistics from './UserStatistics'; // Import nowego komponentu
import './UserList.css'; // Import własnego pliku CSS

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('add'); // 'add', 'edit', 'view'
  const [error, setError] = useState('');
  const [page, setPage] = useState(1); // Aktualna strona
  const [itemsPerPage] = useState(20); // Liczba elementów na stronę
  const [statistics, setStatistics] = useState(null); // Dodaj stan dla statystyk


  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      const { data } = await fetchUsers();
      setUsers(data);
      setFilteredUsers(data); // Inicjujemy listę filtrowaną
    } catch (err) {
      console.error('Błąd podczas ładowania użytkowników:', err);
      setError('Nie udało się załadować listy użytkowników.');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Czy na pewno chcesz usunąć tego użytkownika?')) {
      try {
        await deleteUser(id);
        loadUsers();
      } catch (err) {
        console.error('Błąd podczas usuwania użytkownika:', err);
        setError('Nie udało się usunąć użytkownika.');
      }
    }
  };

  const handleModalOpen = (mode, id = null) => {
    setModalMode(mode);
    setSelectedUserId(id);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedUserId(null);
    setStatistics(null); // Resetuj statystyki przy zamykaniu modala
    loadUsers();
  };

  // Obsługuje zmianę strony
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Obsługuje filtrowanie
  const handleFilterChange = (e) => {
    const query = e.target.value.toLowerCase();
    const filteredData = users.filter((user) =>
      user.fullName.toLowerCase().includes(query) ||
      user.email?.toLowerCase().includes(query) ||
      user.phoneNumber?.toLowerCase().includes(query)
    );
    setFilteredUsers(filteredData);
    setPage(1); // Resetuj stronę na pierwszą
  };

  // Wyświetla użytkowników na bieżącej stronie
  const currentPageData = filteredUsers.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <div>
      {error && <Alert variant="danger">{error}</Alert>}

      {/* Wyszukiwanie */}
      <input
        type="text"
        placeholder="Wyszukaj..."
        onChange={handleFilterChange}
        className="mb-3 form-control"
      />

      <Button variant="primary" onClick={() => handleModalOpen('add')} className="mb-3">
        Dodaj nowego użytkownika
      </Button>

      <div className="table-responsive">
        <table className="table table-hover custom-table">
          <thead>
            <tr>
              <th>Login</th>
              <th>Imię i nazwisko</th>
              <th>Email</th>
              <th>Telefon</th>
              <th>Akcje</th>
            </tr>
          </thead>
          <tbody>
            {currentPageData.map((user) => (
              <tr key={user.id}>
                <td>{user.login}</td>
                <td>{user.fullName}</td>
                <td>{user.email}</td>
                <td>{user.phoneNumber}</td>
                <td>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => handleModalOpen('statistics', user.id)}
                    className="me-2"
                  >
                    Statystyki
                  </Button>
                  <Button
                    variant="info"
                    size="sm"
                    onClick={() => handleModalOpen('view', user.id)}
                    className="me-2"
                  >
                    Podgląd
                  </Button>
                  <Button
                    variant="warning"
                    size="sm"
                    onClick={() => handleModalOpen('edit', user.id)}
                    className="me-2"
                  >
                    Edytuj
                  </Button>
                  <Button variant="danger" size="sm" onClick={() => handleDelete(user.id)}>
                    Usuń
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Paginacja */}
      <Pagination>
        <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page === 1} />
        {Array.from({ length: Math.ceil(filteredUsers.length / itemsPerPage) }, (_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === page}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => handlePageChange(page + 1)}
          disabled={page === Math.ceil(filteredUsers.length / itemsPerPage)}
        />
      </Pagination>

      {/* Modal */}
      <Modal show={showModal} onHide={handleModalClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {modalMode === 'add'
              ? 'Dodaj użytkownika'
              : modalMode === 'edit'
              ? 'Edytuj użytkownika'
              : modalMode === 'view'
              ? 'Podgląd użytkownika'
              : 'Statystyki użytkownika'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalMode === 'statistics' ? (
            <UserStatistics userId={selectedUserId} />
          ) : (
            <UserForm
              userId={selectedUserId}
              mode={modalMode}
              onClose={handleModalClose}
              readOnly={modalMode === 'view'}
              addNew={modalMode === 'add'}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserList;